import { Box, Divider, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  CalendarIcon,
  OnlineMeetingIcon,
  Profile3Icon,
} from '@irishlifedigitalhub/ds.icons';
import { ReactComponent as HeadphoneIcon } from '../../../../images/headphone.svg';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import Tick from '../../../../images/tick.png';
import { ListItem } from '../../../ListItem';
import { TitleAndTextWithIcon } from '../../../TitleAndTextWithIcon';
import { BoxWhiteRounded } from '../../../BoxWhiteRounded';
import { useBookAnAppointmentV4 } from 'common/components/organisms/BookAnAppointmentV4/hooks/useBookAnAppointmentV4';
import { Spinner } from '@irishlifedigitalhub/ds.atoms.spinner';
import { navigate } from '@reach/router';
import { Alert } from '@irishlifedigitalhub/ds.molecules.alert';
import { CustomLink } from '../../../CustomLink';
import { AddToMyCalendar } from './AddToMyCalendar';
import { AddToCalendarProps } from '@irishlifedigitalhub/ds.molecules.add-to-calendar';
import { AdvisorDetails } from '@irishlifedigitalhub/ds.organisms.book-appointment/src/lib/utils/interfaces';

const Step3 = () => {
  const { state } = useBookAnAppointmentV4();
  return (
    <BoxWhiteRounded className="step-3">
      {state.context.loading && (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      )}

      {!state.context.loading && state.context.submittedSuccessfully && (
        <LayoutSuccessfully />
      )}
      {!state.context.loading && !state.context.submittedSuccessfully && (
        <LayoutError />
      )}
    </BoxWhiteRounded>
  );
};

const LayoutSuccessfully = () => {
  const { state } = useBookAnAppointmentV4();
  const [isOpenCalendarBox, setIsOpenCalendarBox] = useState<boolean>(false);
  const advisorDetails: AdvisorDetails = state.context
    .response as AdvisorDetails;

  const eventDescription = (advisorDetails: AdvisorDetails) => {
    return `You have an appointment scheduled with your Irish Life Financial Services advisor. Please check your confirmation email for further details.

Advisor Name: ${advisorDetails.advisorName}
Advisor Phone: ${advisorDetails.advisorPhone}
Advisor Email: ${advisorDetails.advisorEmail}
`;
  };

  const appointmentDate = state.context.currentDate;
  const year = appointmentDate.getFullYear();
  const month = String(appointmentDate.getMonth() + 1).padStart(2, '0');
  const day = String(appointmentDate.getDate()).padStart(2, '0');
  const appointmentTime = state.context.selectedTime;

  const appointmentDateTime = `${year}-${month}-${day}T${appointmentTime}`;

  const calendarEvent: AddToCalendarProps['event'] = {
    title: `${advisorDetails.advisorName} - Irish Life`,
    description: eventDescription(advisorDetails),
    start: appointmentDateTime,
    duration: [1, 'hour'],
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {/* Status Card */}
      <Box display="flex" gap="16px" alignItems="center">
        <Image src={Tick} style={{ width: '34px', height: '34px' }} />
        <Text
          flex="1"
          color={'#0C1E68'}
          fontSize={'24px'}
          fontWeight={'medium'}
          lineHeight={'28.8px'}
        >
          {state.context.cmsData.step_3__title___confirmed}
        </Text>
      </Box>

      {/* What to expect section */}
      <Box display="flex" flexDirection="column" gap="18px">
        <Text
          flex="1"
          color={'#0C1E68'}
          fontSize={'20px'}
          fontWeight={'semibold'}
          lineHeight={'24px'}
        >
          {state.context.cmsData.step_3__subtitle}
        </Text>
        {/* Bulleted list */}
        <Box display="flex" flexDirection="column" gap="20px">
          {state.context.cmsData.step_3__instructions.map((e, index) => (
            <ListItem key={e.codename} number={index + 1} text={e.text} />
          ))}
        </Box>
      </Box>

      {/* Line */}
      <Divider
        orientation="horizontal"
        borderColor="#D8D6DF"
        borderWidth="1.5px"
      />

      {/* Booker summary & confirm */}
      <Box display="flex" flexDirection="column" gap="24px">
        <TitleAndTextWithIcon
          title="Your appointment is with:"
          text={
            (state.context.response as any).advisorName
              ? (state.context.response as any).advisorName
              : 'Qualified Irish Life Financial Advisor'
          }
          Icon={HeadphoneIcon as any}
          IconStyle={{
            width: '1em',
            height: '1em',
            display: 'inline-block',
            lineHeight: '1em',
            flexShrink: 0,
            color: 'rgb(113, 127, 226)',
            verticalAlign: 'middle',
            marginTop: '5px',
          }}
        />
        <TitleAndTextWithIcon
          title="Time and date:"
          text={`${
            state.context.selectedTimeLabel
          }, ${state.context.currentDate.toLocaleDateString(undefined, {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
          })}`}
          Icon={CalendarIcon}
          IconStyle={{ marginTop: '5px' }}
        />
        <TitleAndTextWithIcon
          title="Format:"
          text={state.context.cmsData.step_3__format_text}
          Icon={OnlineMeetingIcon}
          IconStyle={{ marginTop: '5px' }}
        />
        <TitleAndTextWithIcon
          title="Review type:"
          text={
            state.context.formData.HasPartner === 'individual'
              ? 'Individual'
              : 'With partner'
          }
          Icon={Profile3Icon}
          IconStyle={{ marginTop: '5px' }}
        />
      </Box>

      {/* CTAs Mobile */}
      <Box display="flex" flexDirection="column" marginTop="10px" gap="20px">
        <Button
          size="md"
          type="button"
          variant="secondary"
          rightIcon={<CalendarIcon w="28px" h="28px" />}
          onClick={() => {
            isOpenCalendarBox
              ? setIsOpenCalendarBox(false)
              : setIsOpenCalendarBox(true);
          }}
        >
          Add to my calendar
        </Button>

        {isOpenCalendarBox && <AddToMyCalendar event={calendarEvent} />}
        <Button
          size="md"
          type="button"
          variant="solid"
          colorScheme="vibrant"
          onClick={() => {
            navigate('/');
          }}
        >
          Return to Home
        </Button>
      </Box>
    </Box>
  );
};

const LayoutError = () => {
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" gap="16px" alignItems="center">
        <Text
          flex="1"
          color={'#0C1E68'}
          fontSize={'24px'}
          fontWeight={'medium'}
          lineHeight={'28.8px'}
        >
          Unfortunately something went wrong.
        </Text>
      </Box>
      <Alert
        status="error"
        textProps={{
          fontWeight: 'medium',
        }}
      >
        If you would like to speak to customer service or a financial advisor,
        you can call 01 704 1979 (9am - 5pm Mon-Thurs, 9am -4pm Fri)
        <br />
        <br />
        Alternatively you can request a callback from a financial advisor here:
        <br />
        <CustomLink
          text="https://www.irishlife.ie/get-advice"
          url={'/get-advice'}
          enableIcon={false}
          underline={false}
          target="_blank"
        />
      </Alert>
    </Box>
  );
};

export { Step3 };
