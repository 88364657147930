import { Box } from '@chakra-ui/react';
import React from 'react';
import { CountCircle } from './CountCircle';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';

interface IListItem {
  text: string;
  number: number;
}

const ListItem = ({ text, number }: IListItem) => {
  return (
    <Box display="flex" gap="12px">
      {/* Count circle */}
      <CountCircle number={number} />
      <Box>
        <Text flex="1" color={'#4E4670'} variant="body-md">
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export { ListItem };
